import { RichTextBlock } from "@libry-content/types";

export const richTextHasContent = (blocks: RichTextBlock = []): boolean => {
  return blocks.some((block) => {
    switch (block._type) {
      case "block":
        return block.children?.find(({ text }) => !!text);
      case "table":
        return true;
    }
  });
};
