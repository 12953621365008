import { isDevelopment } from "@biblioteksentralen/js-utils";
import { useRouter } from "next/router";
import Script from "next/script";
import { usePreviewContext } from "../../components/preview/previewContext";
import { isProductionDataset } from "../currentDataset";
import React from "react";

export function PlausibleSetup() {
  const site = useRouter().query.site;
  const previewContext = usePreviewContext();

  // Sørger for at vi ikke logger metrikker når siden vises som preview i studio
  if (previewContext.isOn !== false) return null;

  const topDomain = getPlausibleDomain();

  // Logger både til eget dashboard for biblioteket, og felles dashboard for alle biblioteker https://plausible.io/docs/plausible-script#is-there-a-roll-up-view
  const dataDomains = typeof site === "string" ? [`felles.${topDomain}`, `${site}.${topDomain}`] : [topDomain];

  return <Script defer data-domain={dataDomains.join(",")} src="https://plausible.io/js/plausible.local.js" />;
}

export function getPlausibleDomain(): string {
  if (isProductionDataset && !isDevelopment()) return "bibliotek.io";

  if (isDevelopment()) return "bibliotek-local.io";

  return "bibliotek-test.io";
}

// https://plausible.io/docs/custom-event-goals
export function logEvent(event: string, extraData?: Record<string, string | number | boolean>) {
  try {
    /** @ts-ignore */
    typeof plausible !== "undefined" && plausible(event, extraData ? { props: extraData } : undefined);
  } catch (e) {
    console.error(e);
  }
}
