import useSWR from "swr";
import { sanityClient } from "../../utils/sanity/client";

interface CurrentSanityUser {
  id: string;
  name: string;
  email: string;
  profileImage: string | null;
  role: string;
}

const clientWithCredidentials = sanityClient.withConfig({
  withCredentials: true,
});

export function useSanityAuth() {
  const response = useSWR<CurrentSanityUser>("sanityAuthStatus", () => clientWithCredidentials.users.getById("me"));

  return {
    ...response.data,
    isAuthenticated: !!response.data?.id,
    logOut: () => clientWithCredidentials.auth.logout(),
    revalidate: response.revalidate,
  };
}
