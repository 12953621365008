import { isDevelopment } from "@biblioteksentralen/js-utils";

function requireEnv(key: string, value: string | undefined): string {
  if (!value) {
    console.error(`🚦🛑 Environment variable ${key} is empty or undefined`);
    return "";
  }
  return value;
}

// Note: For the NextJS compiler to find environment variables, they cannot be accessed
// using object destructuring or any other way than process.env.NAME.
export const platform = Object.freeze({
  /**
   * The Libry Content platform domain for the current deployment.
   * Example values: 'bibliotek.io', 'pr133.qa.bibliotek-test.io', 'localhost:3000'
   */
  domain: requireEnv("SANITY_STUDIO_PLATFORM_DOMAIN", process.env.SANITY_STUDIO_PLATFORM_DOMAIN),
});

export const getPlatformUrl = (): string => `${isDevelopment() ? "http" : "https"}://${platform.domain}`;
