import { colors } from "@biblioteksentralen/js-utils";
import styled from "@emotion/styled";

const Style = styled.div`
  background-color: ${colors.statusGreen};
  color: white;
  padding: 0.25rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.7rem;
`;

const dataset = process.env.SANITY_STUDIO_API_DATASET;

function Testenvironmentbanner() {
  if (dataset === "production") {
    return null;
  }

  return <Style>Dette er et testmiljø (Datasett: {dataset})</Style>;
}

export default Testenvironmentbanner;
