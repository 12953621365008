import { sanityConfig } from "@libry-content/common";
import { ClientConfig, createClient, createPreviewSubscriptionHook } from "next-sanity";
import createImageUrlBuilder from "@sanity/image-url";
import { SanityImageObject, SanityProjectDetails } from "@sanity/image-url/lib/types/types";

const frontendSanityConfig: ClientConfig = {
  ...sanityConfig,
  useCdn: process.env.NODE_ENV === "production",
};

export const imageUrlBuilder = (source?: SanityImageObject) => {
  // Sånn som vi har satt opp bilder i sanity nå kan bilde-objektet eksistere selv om det ikke har asset. Det får createImageUrlBuilder til å krasje
  if (!source?.asset) return undefined;
  try {
    return createImageUrlBuilder(frontendSanityConfig as SanityProjectDetails).image(source);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

export const usePreviewSubscription = createPreviewSubscriptionHook(frontendSanityConfig as SanityProjectDetails);

export const sanityClient = createClient(frontendSanityConfig);
