import { createContext, ReactNode, useContext, useState } from "react";
import { useMount } from "react-use";
import { LocalConfig } from "../../utils/localConfig";

interface EditInSanityContextI {
  editMode: boolean;
  toggle: () => void;
}

const initialValue: EditInSanityContextI = {
  editMode: false,
  toggle: () => null,
};

const Context = createContext<EditInSanityContextI>(initialValue);

export const useEditInSanityContext = () => useContext(Context);

interface Props {
  children: ReactNode;
}

export const EditInSanityContextProvider = (props: Props) => {
  const [editMode, setEditMode] = useState(initialValue.editMode);

  useMount(() => {
    const storedState = LocalConfig.getConfig().editMode;
    return setEditMode(storedState);
  });

  const toggle = () => {
    const nextState = !editMode;
    LocalConfig.setConfig({ editMode: nextState });
    setEditMode(nextState);
  };

  return (
    <Context.Provider
      value={{
        editMode,
        toggle,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
